.product-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 200px repeat(4, min-content) auto;
  place-items: center;
  min-height: 100%;
  padding: 1rem;
  /* margin-bottom: 1rem; */
}

.prod-container {
  display: flex;
  justify-content: center;
  text-align: center;

  width: 100%;
  /* height: min-content; */
  margin: 0 auto;
  max-height: 300px;
  min-height: min-content;
}
.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}
.prod-image {
  max-height: 300px;
  min-height: 250px;
}
.prod-data {
  justify-self: center;
  width: 100%;
  min-height: min-content;
  max-height: 300px;
  margin: 0 auto;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
}

.prod-name-text {
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.prod-data-text {
  font-size: 25px;
  font-weight: 400;
  margin: 0.3rem;
  text-align: center;
}

.prod-price {
  display: flex;
  justify-self: center;
  align-self: center;
  min-height: min-content;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  text-align: center;
}

.prod-price > p {
  font-size: 50px;
  font-weight: 600;
  margin: 0 auto;
}

.prod-stock {
  justify-self: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 7px;
}

.stock-title {
  text-align: center;
  font-weight: 600;
  margin: 0.5rem;
}

.stock-visualizer{
  width: 350px;
  margin: 0 auto;
}

.stock-text {
  text-align: center;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.prod-back-btn-row {
  display: flex;
  height: min-content;
}

.prod-back-btn {
  justify-self: center;
  align-self:flex-end;
}

.scroll-down-footer {
  position: absolute;
  left: 0;
  bottom: 0;
}

.btn-icon {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 45px;
  width: 45px;
}

/* .prod-back-btn-row > button {
  background-color: white;
  color: black;
  border: white;
} */