.border {
  display: grid;
  height: 100%;
  place-items: center;
  align-content: flex-start;
  padding: 1rem;
  background-color: rgb(236, 236, 236);
}

.citykids-home-container {
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 1fr;
  place-items: center;
  gap: 1rem;
  min-height: min-content;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
}

.client-logo-header {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

/* video {
  z-index: -1;
} */

.citykids-scanner-row {
  position: relative;
  height: min-content;
  justify-self: center;
  align-self: center;
}

.line-camera-indicator {
  position: relative;
  top: 175px;
  left: 15%;
  border: 1.5px solid black;
  opacity: 0.6;
  width: 70%;
  margin: 0;
  z-index: 999;
}

.box-camera-indicator {
  position: absolute;
  top: 75px;
  left: 10%;
  border: 3px solid black;
  border-radius: 7px;
  opacity: 0.6;
  width: 80%;
  margin: 0;
  height: 200px;
  display: block;
  z-index: 999;

}

.divider {
  border: 2px solid rgb(97, 97, 97);
  width: 90%;
  margin: 0;
}

.citykids-scanner-text {
  display: flex;
  text-align: center;
  height: 100%;
}

.citykids-scanner-text > p {
  font-size: 19px;
  font-weight: 500;
  margin: 0;
}

.client-logo-row {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
  height: min-content;
}

.citykids-manual-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .citykids-manual-button > button {
  background-color: white;
  color: black;
  border: white;
} */

/* .citykids-manual-button > button:hover {
  background-color: white;
  color: black;
  border: white;
} */
