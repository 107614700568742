
.loading-container {
  height: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-rows: 50px 40px;
  justify-content: center;
  align-content: center;
}

.loading-spinner {
  justify-self: center;
  align-self: center;
}
.loading-text {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
