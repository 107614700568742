.border {
  display: grid;
  height: 100%;
  place-items: center;
  align-content: flex-start;
  padding: 1rem;
  background-color: rgb(236, 236, 236);
}

.home-container {
  display: grid;
  /* grid-template-rows: 200px repeat(5,min-content); */
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 1fr;
  place-items: center;
  gap: 1rem;
  min-height: min-content;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
}

.client-logo-header {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

video {
  z-index: -1;
}

.scanner-row {
  height: min-content;
  justify-self: center;
  align-self: center;
}

.camera-indicator {
  position: relative;
  top: 175px;
  left: 10%;
  /* z-index: 999; */
  border: 1.5px solid black;

  opacity: 0.6;
  /* height: 3px; */
  /* width: 70%; */
  /* background-color: white; */
  /* border: none; */
  /* border-style: */
  width: 80%;
  margin: 0;
}

.divider {
  border: 2px solid rgb(97, 97, 97);
  width: 90%;
  margin: 0;
}

.scanner-text {
  display: flex;
  text-align: center;
  height: 100%;
}

.scanner-text > p {
  font-size: 19px;
  font-weight: 500;
  margin: 0;
}

.client-logo-row {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
  height: min-content;
}

.manual-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .manual-button > button {
  background-color: white;
  color: black;
  border: white;
} */

/* .manual-button > button:hover {
  background-color: white;
  color: black;
  border: white;
} */
