.citykids-code-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows:repeat(3,min-content);
  place-items: center;
  justify-self: center;
  background-color: white;
  min-height: 100%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
  padding: 1rem;
}

.citykids-form-row {
  display: inline-grid;
  justify-self: center;
  align-self: flex-start;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
}

.citykids-form {
  margin: 0 auto;
  margin: 1rem;
}

.form-label {
  font-size: 17px;
  font-weight: 500;
}

.citykids-price-button{
  grid-column: 3/4;

}

.citykids-back-btn{
  grid-column: 1/2;
}


.citykids-back-btn-row {
  display: grid;
  grid-template-columns:  repeat(3,auto);
  width: 100%;
  height: 100%;
}

.citykids-back-btn-row > button {
  left: 0;
  align-self: flex-end;
  /* background-color: white;
  color: black;
  border: white; */
}
