body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 100vh; */
  height: 100dvh;
  max-height: -webkit-fill-available;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  /* margin-bottom: 1rem; */
  /* display: flex; */
  /* width: 100%; */
  /* height: min-content;
  max-height: 35px;
  min-height: 20px; */
  /* margin: 1rem auto; */
}

footer > img {
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stock-table{
    margin-left: 2rem;
    margin-right: 2rem;
}

.loading-container {
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-template-rows: 50px 40px;
  justify-content: center;
  align-content: center;
}

.loading-spinner {
  justify-self: center;
  align-self: center;
}
.loading-text {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.border {
  display: grid;
  height: 100%;
  place-items: center;
  align-content: flex-start;
  padding: 1rem;
  background-color: rgb(236, 236, 236);
}

.home-container {
  display: grid;
  /* grid-template-rows: 200px repeat(5,min-content); */
  grid-template-rows: repeat(4, -webkit-min-content);
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 1fr;
  place-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  min-height: -webkit-min-content;
  min-height: min-content;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
}

.client-logo-header {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

video {
  z-index: -1;
}

.scanner-row {
  height: -webkit-min-content;
  height: min-content;
  justify-self: center;
  align-self: center;
}

.camera-indicator {
  position: relative;
  top: 175px;
  left: 10%;
  /* z-index: 999; */
  border: 1.5px solid black;

  opacity: 0.6;
  /* height: 3px; */
  /* width: 70%; */
  /* background-color: white; */
  /* border: none; */
  /* border-style: */
  width: 80%;
  margin: 0;
}

.divider {
  border: 2px solid rgb(97, 97, 97);
  width: 90%;
  margin: 0;
}

.scanner-text {
  display: flex;
  text-align: center;
  height: 100%;
}

.scanner-text > p {
  font-size: 19px;
  font-weight: 500;
  margin: 0;
}

.client-logo-row {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
  height: -webkit-min-content;
  height: min-content;
}

.manual-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .manual-button > button {
  background-color: white;
  color: black;
  border: white;
} */

/* .manual-button > button:hover {
  background-color: white;
  color: black;
  border: white;
} */

.code-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows:repeat(3,-webkit-min-content);
  grid-template-rows:repeat(3,min-content);
  place-items: center;
  justify-self: center;
  background-color: white;
  min-height: 100%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
  padding: 1rem;
}

.form-row {
  display: inline-grid;
  justify-self: center;
  align-self: flex-start;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
}

.form {
  margin: 0 auto;
  margin: 1rem;
}

.form-label {
  font-size: 17px;
  font-weight: 500;
}

.price-button{
  grid-column: 3/4;

}

.back-btn{
  grid-column: 1/2;
}


.back-btn-row {
  display: grid;
  grid-template-columns:  repeat(3,auto);
  width: 100%;
  height: 100%;
}

.back-btn-row > button {
  left: 0;
  align-self: flex-end;
  /* background-color: white;
  color: black;
  border: white; */
}

.product-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 200px repeat(4, -webkit-min-content) auto;
  grid-template-rows: 200px repeat(4, min-content) auto;
  place-items: center;
  min-height: 100%;
  padding: 1rem;
  /* margin-bottom: 1rem; */
}

.prod-container {
  display: flex;
  justify-content: center;
  text-align: center;

  width: 100%;
  /* height: min-content; */
  margin: 0 auto;
  max-height: 300px;
  min-height: -webkit-min-content;
  min-height: min-content;
}
.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}
.prod-image {
  max-height: 300px;
  min-height: 250px;
}
.prod-data {
  justify-self: center;
  width: 100%;
  min-height: -webkit-min-content;
  min-height: min-content;
  max-height: 300px;
  margin: 0 auto;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
}

.prod-name-text {
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.prod-data-text {
  font-size: 25px;
  font-weight: 400;
  margin: 0.3rem;
  text-align: center;
}

.prod-price {
  display: flex;
  justify-self: center;
  align-self: center;
  min-height: -webkit-min-content;
  min-height: min-content;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  text-align: center;
}

.prod-price > p {
  font-size: 50px;
  font-weight: 600;
  margin: 0 auto;
}

.prod-stock {
  justify-self: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 7px;
}

.stock-title {
  text-align: center;
  font-weight: 600;
  margin: 0.5rem;
}

.stock-visualizer{
  width: 350px;
  margin: 0 auto;
}

.stock-text {
  text-align: center;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.prod-back-btn-row {
  display: flex;
  height: -webkit-min-content;
  height: min-content;
}

.prod-back-btn {
  justify-self: center;
  align-self:flex-end;
}

.scroll-down-footer {
  position: absolute;
  left: 0;
  bottom: 0;
}

.btn-icon {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 45px;
  width: 45px;
}

/* .prod-back-btn-row > button {
  background-color: white;
  color: black;
  border: white;
} */
.border {
  display: grid;
  height: 100%;
  place-items: center;
  align-content: flex-start;
  padding: 1rem;
  background-color: rgb(236, 236, 236);
}

.citykids-home-container {
  display: grid;
  grid-template-rows: repeat(4, -webkit-min-content);
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 1fr;
  place-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  min-height: -webkit-min-content;
  min-height: min-content;
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
}

.client-logo-header {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

/* video {
  z-index: -1;
} */

.citykids-scanner-row {
  position: relative;
  height: -webkit-min-content;
  height: min-content;
  justify-self: center;
  align-self: center;
}

.line-camera-indicator {
  position: relative;
  top: 175px;
  left: 15%;
  border: 1.5px solid black;
  opacity: 0.6;
  width: 70%;
  margin: 0;
  z-index: 999;
}

.box-camera-indicator {
  position: absolute;
  top: 75px;
  left: 10%;
  border: 3px solid black;
  border-radius: 7px;
  opacity: 0.6;
  width: 80%;
  margin: 0;
  height: 200px;
  display: block;
  z-index: 999;

}

.divider {
  border: 2px solid rgb(97, 97, 97);
  width: 90%;
  margin: 0;
}

.citykids-scanner-text {
  display: flex;
  text-align: center;
  height: 100%;
}

.citykids-scanner-text > p {
  font-size: 19px;
  font-weight: 500;
  margin: 0;
}

.client-logo-row {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  justify-self: center;
  align-self: center;
}

.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
  height: -webkit-min-content;
  height: min-content;
}

.citykids-manual-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  height: 100%;
  width: 100%;
}

/* .citykids-manual-button > button {
  background-color: white;
  color: black;
  border: white;
} */

/* .citykids-manual-button > button:hover {
  background-color: white;
  color: black;
  border: white;
} */

.citykids-code-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows:repeat(3,-webkit-min-content);
  grid-template-rows:repeat(3,min-content);
  place-items: center;
  justify-self: center;
  background-color: white;
  min-height: 100%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
  padding: 1rem;
}

.citykids-form-row {
  display: inline-grid;
  justify-self: center;
  align-self: flex-start;
  width: 100%;
  border: 1px solid rgb(218, 218, 218);
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
}

.citykids-form {
  margin: 0 auto;
  margin: 1rem;
}

.form-label {
  font-size: 17px;
  font-weight: 500;
}

.citykids-price-button{
  grid-column: 3/4;

}

.citykids-back-btn{
  grid-column: 1/2;
}


.citykids-back-btn-row {
  display: grid;
  grid-template-columns:  repeat(3,auto);
  width: 100%;
  height: 100%;
}

.citykids-back-btn-row > button {
  left: 0;
  align-self: flex-end;
  /* background-color: white;
  color: black;
  border: white; */
}

.citykids-product-container {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 200px repeat(4, -webkit-min-content) auto;
  grid-template-rows: 200px repeat(4, min-content) auto;
  place-items: center;
  min-height: 100%;
  padding: 1rem;
  /* margin-bottom: 1rem; */
}

.citykids-prod-container {
  display: flex;
  justify-content: center;
  text-align: center;

  width: 100%;
  /* height: min-content; */
  margin: 0 auto;
  max-height: 300px;
  min-height: -webkit-min-content;
  min-height: min-content;
}
.client-logo {
  min-height: 100px;
  max-height: 200px;
  max-width: 300px;
}
.prod-image {
  max-height: 300px;
  min-height: 250px;
}
.citykids-prod-data {
  justify-self: center;
  width: 100%;
  min-height: -webkit-min-content;
  min-height: min-content;
  max-height: 300px;
  margin: 0 auto;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
}

.citykids-prod-name-text {
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.citykids-prod-data-text {
  font-size: 25px;
  font-weight: 400;
  margin: 0.3rem;
  text-align: center;
}

.citykids-prod-price {
  display: flex;
  justify-self: center;
  align-self: center;
  min-height: -webkit-min-content;
  min-height: min-content;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  text-align: center;
}

.citykids-prod-price > p {
  font-size: 50px;
  font-weight: 600;
  margin: 0 auto;
}

.citykids-prod-stock {
  justify-self: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 7px;
}

.citykids-stock-title {
  text-align: center;
  font-weight: 600;
  margin: 0.5rem;
}

.citykids-stock-visualizer{
  width: 350px;
  margin: 0 auto;
}

.citykids-stock-text {
  text-align: center;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}

.citykids-prod-back-btn-row {
  display: flex;
  height: -webkit-min-content;
  height: min-content;
}

.citykids-prod-back-btn {
  justify-self: center;
  align-self:flex-end;
}

.citykids-scroll-down-footer {
  position: absolute;
  left: 0;
  bottom: 0;
}

.citykids-btn-icon {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: 45px;
  width: 45px;
}

/* .citykids-prod-back-btn-row > button {
  background-color: white;
  color: black;
  border: white;
} */
